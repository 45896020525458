import { Suspense, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.less';
import AppProtected from './AppProtected';
import { SET_LOGIN_STATUS, useAuth } from './hooks/auth/AuthContext';
import { IntlProvider } from 'react-intl';
import { useLayout } from './hooks/layout/LayoutContext';
import jwtDecode from 'jwt-decode';

//Import i18n files
import messages_en from './assets/i18n/en.json';
import message_zh_CN from './assets/i18n/zh_CN.json';
import message_th from './assets/i18n/th.json';
import AppMobileProtected from './AppMobileProtected';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Spin } from 'antd';

const App = () => {
  const { authDispatch } = useAuth();
  const [cookies] = useCookies();
  const { layoutState } = useLayout();

  const messages: any = {
    'en': messages_en,
    'zh-CN': message_zh_CN,
    'th': message_th
  };

  //First load
  useEffect(() => {
    const token = cookies[process.env.REACT_APP_REFRESH_TOKEN || 'gmf-rt'];
    if (token) {
      const { isActive }: any = jwtDecode(token);
      if (isActive) {
        authDispatch({ type: SET_LOGIN_STATUS, payload: token ? true : false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <IntlProvider locale={layoutState.locale} messages={messages[layoutState.locale]}>
        <Routes>
          <Route path="*" element={<Navigate to='/home' replace />} />
          <Route path="/home/*" element={
            window.innerWidth > 760 ?
              <Suspense fallback={<Spin />}>
                <AppProtected />
              </Suspense> :
              <Suspense fallback={<Spin />}>
                <AppMobileProtected />
              </Suspense>
          } />
        </Routes>
      </IntlProvider>
    </>
  );
}

export default App;
