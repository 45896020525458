import { Routes, Route } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import { useLayout } from './hooks/layout/LayoutContext';
import { useIntl } from 'react-intl';
import './AppMobile.less';
import HomeMobile from './pages/_mobile/home/HomeMobile';

const { Content } = Layout;

const AppMobileProtected = () => {
    const intl = useIntl();
    const { layoutState } = useLayout();

    return (<Spin tip={intl.formatMessage({ id: "SPINNER_TIP" })} spinning={layoutState.showLoading}>
        <Layout className="app-mobile-container">
            <Layout className="site-layout">
                <Content className="m-site-layout-background">
                    <Routes>
                        <Route path='' element={<HomeMobile />} />
                    </Routes>
                </Content>
            </Layout>
        </Layout>
    </Spin>);
}

export default AppMobileProtected;