import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { setTokenCookie } from "../../../shared/helpers/set-token-cookie.helper";
import "./HomeMobile.less";

const HomeMobile = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [cookies] = useCookies();

    useEffect(() => {
        let cookie = query.get('cookie');
        let url = query.get('gameUrl');
        let selectedLanguage = query.get('selectedLanguage');
        
        if (cookie && url) {
            setTokenCookie('s', cookie, "bk8.network");

            if (selectedLanguage) {
                setTokenCookie('selectedLanguage', selectedLanguage, "bk8.network");
            }

            window.location.replace(url);
        }
    }, [])

    return (
        <>
            <div className="home-mobile-container">
            </div>
        </>
    );
};

export default HomeMobile;
